
import HeroController from './HeroController'

export default {
  name: 'HeroReworked',
  components: {
    HeroController
  },
  // Provide is kind of weird for Vue 2 since there's no easy way to make the properties reactive.
  // So we have to wrap them in functions like this instead.
  provide () {
    const { $props: p } = this
    return {
      getGeneralProps: () => ({
        backgroundColor: p.backgroundColor,
        backgroundColorHex: p.backgroundColorHex,
        backgroundGradient: p.backgroundGradient,
        lazyload: p.lazyload,
        desktopImage: p.desktopImage?.filename,
        mobileImage: p.mobileImage?.filename,
        mobileDisplayWidthOfViewportWidth: p.mobileDisplayWidthOfViewportWidth,
        desktopDisplayWidthOfViewportWidth: p.desktopDisplayWidthOfViewportWidth,
        loadImage: p.loadImage,
      }),
      getDesktopProps: () => ({
        image: p.desktopImage?.filename,
        videoSrc: p.desktopVideoSrc,
        tags: p.desktopTags,
        brow: p.desktopBrow,
        headline: p.desktopHeadline,
        headlineSize: p.desktopHeadlineSize,
        bodyText: p.desktopBodyText,
        copyColor: p.desktopCopyColor,
        ctaLabel: p.desktopCtaLabel,
        ctaDestination: p.desktopCtaDestination,
        ctaFillColor: p.desktopCtaFillColor,
        ctaPushToggle: p.desktopCtaPushToggle,
        ctaAlign: p.desktopCtaAlign,
        ctaFullHitArea: p.desktopCtaFullHitArea,
        ctaLabelColor: p.desktopCtaLabelColor,
        alignVertically: p.desktopAlignVertically || 'top',
        alignHorizontally: p.desktopAlignHorizontally || 'left',
        splitToggle: p.desktopSplitToggle,
        splitColor: p.desktopSplitColor,
        splitColorHex: p.desktopSplitColorHex,
        splitAlignment: p.desktopSplitAlignment,
        aspect: p.desktopAspect,
        focus: p.desktopImage?.focus,
      }),
      getMobileProps: () => ({
        image: p.mobileImage?.filename,
        videoSrc: p.mobileVideoSrc,
        tags: p.mobileTags,
        brow: p.mobileBrow,
        headline: p.mobileHeadline,
        headlineSize: p.mobileHeadlineSize,
        bodyText: p.mobileBodyText,
        copyColor: p.mobileCopyColor,
        ctaLabel: p.mobileCtaLabel,
        ctaDestination: p.mobileCtaDestination,
        ctaFillColor: p.mobileCtaFillColor,
        ctaLabelColor: p.mobileCtaLabelColor,
        ctaPushToggle: p.mobileCtaPushToggle,
        ctaAlign: p.mobileCtaAlign,
        ctaFullHitArea: p.mobileCtaFullHitArea,
        alignVertically: p.mobileAlignVertically || 'top',
        alignHorizontally: p.mobileAlignHorizontally || 'left',
        splitToggle: p.mobileSplitToggle,
        splitColor: p.mobileSplitColor,
        splitColorHex: p.mobileSplitColorHex,
        aspect: p.mobileAspect,
        focus: p.mobileImage?.focus,
      })
    }
  },
  props: {
    /* GENERAL */
    backgroundColor: {
      type: String,
      default: ''
    },
    backgroundColorHex: {
      type: String,
      default: null
    },
    backgroundGradient: {
      type: Boolean,
      default: true
    },
    lazyload: {
      type: Boolean,
      default: false
    },
    loadImage: {
      type: Boolean,
      default: true
    },

    /* MOBILE */
    mobileImage: {
      type: [String, Object],
      default: ''
    },
    mobileVideoSrc: {
      type: String,
      default: ''
    },
    mobileTags: {
      type: [String, Object, Array],
      default: ''
    },
    mobileBrow: {
      type: String,
      default: ''
    },
    mobileHeadline: {
      type: String,
      default: ''
    },
    mobileHeadlineSize: {
      type: String,
      default: ''
    },
    mobileBodyText: {
      type: String,
      default: ''
    },
    mobileCopyColor: {
      type: String,
      default: ''
    },
    mobileCtaLabel: {
      type: String,
      default: ''
    },
    mobileCtaDestination: {
      type: [String, Object],
      default: ''
    },
    mobileCtaFillColor: {
      type: String,
      default: ''
    },
    mobileCtaLabelColor: {
      type: String,
      default: ''
    },
    mobileCtaPushToggle: {
      type: Boolean,
      default: false
    },
    mobileCtaAlign: {
      type: String,
      default: ''
    },
    mobileCtaFullHitArea: {
      type: Boolean,
      default: false
    },
    mobileAlignVertically: {
      type: String,
      default: ''
    },
    mobileAlignHorizontally: {
      type: String,
      default: ''
    },
    mobileSplitToggle: {
      type: Boolean,
      default: false
    },
    mobileSplitColor: {
      type: String,
      default: ''
    },
    mobileSplitColorHex: {
      type: String,
      default: null
    },
    mobileAspect: {
      type: String,
      default: ''
    },
    /**
     * Image display sizes for optimized images
     * Can be sent from a parent, ie the grid component
     * Defaults to 100vw (i.e. browser media selector) if not sent
     */
    mobileDisplayWidthOfViewportWidth: {
      type: Number,
      required: false,
      default: null
    },

    /* DESKTOP */
    desktopImage: {
      type: [String, Object],
      default: ''
    },
    desktopVideoSrc: {
      type: String,
      default: ''
    },
    desktopTags: {
      type: [String, Object, Array],
      default: ''
    },
    desktopBrow: {
      type: String,
      default: ''
    },
    desktopHeadline: {
      type: String,
      default: ''
    },
    desktopHeadlineSize: {
      type: String,
      default: ''
    },
    desktopBodyText: {
      type: String,
      default: ''
    },
    desktopCopyColor: {
      type: String,
      default: ''
    },
    desktopCtaLabel: {
      type: String,
      default: ''
    },
    desktopCtaDestination: {
      type: [String, Object],
      default: ''
    },
    desktopCtaFillColor: {
      type: String,
      default: ''
    },
    desktopCtaPushToggle: {
      type: Boolean,
      default: false
    },
    desktopCtaAlign: {
      type: String,
      default: ''
    },
    desktopCtaFullHitArea: {
      type: Boolean,
      default: false
    },
    desktopCtaLabelColor: {
      type: String,
      default: ''
    },
    desktopAlignVertically: {
      type: String,
      default: ''
    },
    desktopAlignHorizontally: {
      type: String,
      default: ''
    },
    desktopSplitToggle: {
      type: Boolean,
      default: false
    },
    desktopSplitColor: {
      type: String,
      default: ''
    },
    desktopSplitColorHex: {
      type: String,
      default: null
    },
    desktopSplitAlignment: {
      type: String,
      default: ''
    },
    desktopAspect: {
      type: String,
      default: ''
    },
    /**
     * Image display sizes for optimized images
     * Can be sent from a parent, ie the grid component
     * Defaults to 100 (i.e. browser media selector) if not sent
     */
    desktopDisplayWidthOfViewportWidth: {
      type: Number,
      required: false,
      default: 100
    },
  },
  data () {
    return {
      isMobile: null,
    }
  },
  mounted () {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      if (typeof this.$refs.test !== 'undefined') {
        this.isMobile = window.getComputedStyle(this.$refs.test).display !== 'none'
      }
    },
  }
}
